@import "normalize.css";
@import "~bootstrap-utilities";
@import "react-toastify/dist/ReactToastify.css";
/*@import 'react-json-pretty/themes/1337.css';*/

html,
body,
#root,
.App {
    height: 100%;
    min-height: 100%;
}

body {
    font-size: 14px;
    font-weight: 400;
    letter-spacing: 0;
    line-height: 1.28581;
    text-transform: none;
    font-family: -apple-system, "BlinkMacSystemFont", "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Open Sans",
        "Helvetica Neue", "Icons16", sans-serif;
}

a {
    text-decoration: none;
    color: inherit;
}
a:visited {
}

@media only screen and (max-width: 899px) {
    .nav-wrapper {
        display: none;
    }

    .nav-wrapper.show-responsive {
        display: block;
        background-color: #fafafa;
        border-bottom: 1px solid gray;
        z-index: 10;
        padding: 2vh;
    }

    .nav-bar {
        position: sticky;
        top: 0;
        right: 0;
        z-index: 20;
        font-size: 20pt;
        background-color: #fafafae5;
        padding: 5px;
        border-bottom: 1px solid gray;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        .title {
            display: flex;
            flex-direction: row;
            align-items: center;
            margin-left: 2vh;
            img {
                max-height: 5vh;
            }
            div {
                margin-left: 2vh;
            }
        }
        .burger {
            margin-right: 2vh;
        }
    }

    .dark {
        background-color: #303030 !important;
    }

    .nav-title > .title {
        display: none;
    }

    .logo {
        display: none;
    }

    .nav-icon {
        margin-right: 10px;
    }

    .page {
        //margin-top: 6vh;
        padding: 20px 8px 20px 8px !important;
    }
}

@media only screen and (min-width: 900px) {

    .loginlogout {
        margin-top: 1rem;
    }
    .nav-bar {
        display: none;
    }

    .container {
        display: flex;
        flex-direction: row;
        margin: auto;
        max-width: 1100px;
        min-height: 100vh;
    }

    .container > * {
        flex-grow: 0;
        -ms-flex-negative: 0;
        flex-shrink: 0;
    }

    .nav-wrapper {
        flex-basis: 270px;
        position: relative;
    }

    .nav {
        height: 100vh;
        margin-left: -999px;
        overflow-y: auto;

        padding-left: 1004px;
        position: fixed;

        display: flex;

        flex-direction: column;
    }

    .nav > *,
    .nav-parts > * {
        padding: 15px 15px 15px 0;
    }

    .nav-burger {
        display: none;
    }

    .nav .title {
        font-size: 20px;
        font-weight: 600;
        margin-right: 10px;
    }

    .nav-title {
        margin-top: 10px;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: horizontal;
        -webkit-box-direction: normal;
        -ms-flex-direction: row;
        flex-direction: row;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
    }

    .nav-title > * {
        -webkit-box-flex: 0;
        -ms-flex-positive: 0;
        flex-grow: 0;
        -ms-flex-negative: 0;
        flex-shrink: 0;
    }

    .nav-title > .title {
        display: block;
    }

    .logo {
        display: block;
        margin-right: 15px;
    }

    .logo > img {
        max-width: 150px;
    }

    .nav-icon {
        margin-right: 10px;
    }

    .nav-parts {
        display: flex;
        flex-direction: column;
        flex-grow: 1;
    }

    .nav-part-upper {
        flex-grow: 1;
    }

    .divider {
        background-image: -webkit-gradient(
            linear,
            left top,
            right top,
            color-stop(0, rgba(16, 22, 26, 0)),
            color-stop(40%, rgba(16, 22, 26, 0.15))
        );
        background-image: linear-gradient(90deg, rgba(16, 22, 26, 0) 0, rgba(16, 22, 26, 0.15) 40%);
        height: 1px;
        margin-left: -50px;
        padding: 0;
    }
}

.page {
    max-width: 830px;
    padding: 20px 20px 20px 20px;
    position: relative;
}

h1 {
    text-transform: uppercase;
    font-weight: 500 !important;
}

.small {
    font-size: 10pt;
}

.pointer {
    cursor: pointer;
}

.center-div {
    margin: auto;
    width: 50%;
    border: 3px solid green;
    padding: 10px;
    text-align: center;
}

.flex-fill {
    flex-grow: 1;
}

.menu-item {
    font-weight: 600;
    text-transform: uppercase;
    -webkit-transition: background-color 200ms linear;
    -ms-transition: background-color 200ms linear;
    transition: background-color 200ms linear;
    border-radius: 5px;
    padding: 5px 7px;
    margin-right: 0;
    padding-right: 0;
    margin-bottom: 1px;
    color: black;

    display: flex;
    flex-direction: row;
    align-items: flex-start;

    line-height: 20px;
    text-decoration: none;
    -webkit-user-select: none;
    user-select: none;
}

.menu-item > * {
    display: inline-block;
}

.menu-item:hover {
    background-color: rgba(167, 182, 194, 0.3);
    cursor: pointer;
    text-decoration: none;
}

.intent-margin {
    width: 20px;
}

.intent-item > .menu-item {
    font-weight: 400;
}

.item-active {
    border-radius: 5px;
    background-color: rgba(138, 155, 168, 0.15);
    color: inherit;
}

.list-unstyled {
    list-style: none;
    margin: 0;
}

.list-unstyled li {
}

.text-muted {
    color: #5c7080;
}

h1 {
    font-size: 36px;
    line-height: 40px;
    margin: 0 0 10px;
    padding: 0;
}

.text-with-icon {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.text-with-icon > div:first-child {
    margin-right: 20px;
    margin-left: 10px;
}

.card-grid {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: min-content min-content min-content min-content;
    gap: 20px 30px;
}

.small-ul {
    margin-block-start: 0;
    margin-block-end: 0;
    padding-inline-start: 20px;
}

.jobMoreInfo {
    border-left: 4px solid red;
}

.uppy-DragDrop-label {
    color: black !important;
}

.job-info-text {
    box-sizing: border-box;

    b {
        font-weight: bold;
    }

    ol,
    ul {
        padding-left: 1.5em;
    }

    p,
    ol,
    ul,
    pre,
    blockquote,
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
        margin: 0;
        padding: 0;
        padding-left: 0px;
        counter-reset: list-1 list-2 list-3 list-4 list-5 list-6 list-7 list-8 list-9;
    }

    ol li:not(.ql-direction-rtl),
    ul li:not(.ql-direction-rtl) {
        padding-left: 1.5em;
    }
    ol > li,
    ul > li {
        list-style-type: none;
    }

    li:not(.ql-direction-rtl)::before {
        margin-left: -1.5em;
        margin-right: 0.3em;
        text-align: right;
    }
    ul > li::before {
        content: "\2022";
    }
    li::before {
        display: inline-block;
        white-space: nowrap;
        width: 1.2em;
    }
}

.large {
    font-size: 13pt;
}
