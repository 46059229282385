.form-grid {
  display: grid;
  grid-template-columns: min-content 1fr;
  grid-template-rows: min-content min-content min-content min-content;
  gap: 1em 20px;
  
}

.form-grid h2 {
  margin-bottom: 0;
  grid-column: 1 / 3;
}

.two {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 0px 10px;
  grid-column: 1 / 3;
}

@media only screen and (max-width: 899px) {
  .two {
    grid-template-columns: 1fr;
    gap: 10px 10px;
  }
}


.twoWithButton {
  display: grid;
  grid-template-columns: 1fr 1fr auto;
  gap: 0px 10px;
  grid-column: 1 / 3;
}

.twoSmallEnd {
  display: grid;
  grid-template-columns: 3fr 1fr;
  gap: 0px 10px;
  grid-column: 1 / 3;
}

.twoSmallStart {
  display: grid;
  grid-template-columns: 1fr 3fr;
  gap: 0px 10px;
  grid-column: 1 / 3;
}

.oneTwoHalf {
  display: grid;
  grid-template-columns: 1fr auto auto;
  gap: 0px 10px;
  grid-column: 1 / 3;
}

.twoHalfOne {
  display: grid;
  grid-template-columns: auto 1fr 1fr;
  gap: 0px 10px;
  grid-column: 1 / 3;
}
.gridCenter {
  place-self: center
}

.lbl {
  grid-column: 1 / 2;
  
  text-align: left;
  place-self: center stretch;
}
.one {
  grid-column: 1 / 3;
}

.radioRow {
  grid-column: 2/3;
  gap: 1em;
  height: 35px;
}

.w-short {
  width: 10em;
}


.starButton {
  background-color: Transparent;
  background-repeat: no-repeat;
  border: none;
  cursor: pointer;
  overflow: hidden;
  outline: none;
}

.mdl-textfield:not(.is-dirty) input::-webkit-datetime-edit {
  color: transparent; 
}

.MuiInputBase-root.Mui-disabled {
  color: black !important;
}

.flag:hover {
  opacity: 0.8;
}

.flag {
  opacity: 0.4;
  span {
    border-radius: .15rem !important;
  }
}

.flag-active {
  opacity: 1 !important
}

.formHeader {
    border-bottom: 1px solid lightgray;
    grid-column: 1/3;
    padding-left: 5px;
}

.MuiFormControl-marginNormal {
  margin: 0 !important;
}